// IE 11 forEach fix
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

import $ from 'jquery';
global.$ = global.jQuery = $;
window.jQuery = $;
window.$ = $;

import jQueryBridget from 'jquery-bridget';

import * as Lazysizes from "lazysizes";
global.Lazysizes = Lazysizes;

import * as Flickity from "flickity";
global.Flickity = Flickity;
Flickity.setJQuery( $ );
jQueryBridget( 'flickity', Flickity, $ );


import * as bootstrap from 'bootstrap';
global.bootstrap = bootstrap;
//jQueryBridget( 'bootstrap', bootstrap, $ );
